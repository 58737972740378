import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Footer from "../components/footer"

const Limpieza = () => (
  <Layout>
    <SEO title="Tarot y oráculos para sanar" />

    <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
      <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-20">
        Tarot y oráculos para sanar
      </h2>
      <div className="w-full lg:w-10/12 lg:flex mx-auto">
        <div className="w-full lg:w-1/3 rounded-lg p-5">
          <img
            src="https://www.datocms-assets.com/41997/1613498920-tarot2.jpg"
            alt="Tarot y oráculos para sanar"
            className=""
          />
          <p className="text-sm text-yellow-100 p-0 py-4">
            Duración de la sesión: entre 2 horas y media a 3 horas
          </p>
          <p className="text-sm text-yellow-100 p-0 pt-0">
            Técnicas: Lectura del Tarot y Oráculos
          </p>
          <p className="text-sm text-yellow-100 p-0 pt-3">
            <Link className="underline">Agendar una cita</Link>
          </p>
        </div>

        <div className="w-full lg:w-2/3 mt-5 lg:mt-0">
          <p>
            La lectura del Tarot & Oráculos para sanar es muy particular y
            poderosa, su objetivo no es adivinatorio. Yo trabajo con energía y
            la tirada que hago es de crecimiento personal y sanación, eso quiere
            decir que mis guías, ángeles y ancestros se conectan con los tuyos
            para darte los mensajes que son pertinentes para tu vida emocional y
            El futuro gracias a nuestras decisiones del día a día está en
            continuo cambio y esta sesión te ayudará a crear esa realidad,
            conectando con tu infinito potencial y poder personal. recibimos
            guía de cuáles son los pasos a seguir en diversas áreas de tu vida.{" "}
          </p>

          <p>
            La tirada es 100% “On Line”, consta de 3 partes, yo les recomiendo
            que reserven 2hrs y media en promedio, en un lugar privado, sin
            distracciones y con buena conexión a internet. Usaremos en el
            desarrollo de la lectura tanto WhatsApp como google meet, además de
            las cartas del tarot y diversos oráculos (personalizados).
          </p>

          <p>
            En mis lecturas siempre tendrás un espacio honesto, libre de juicios
            y total confidencialidad.
          </p>

          <p>
            El futuro gracias a nuestras decisiones del día a día está en
            continuo cambio y esta sesión te ayudará a crear esa realidad,
            conectando con tu infinito potencial y poder personal.
          </p>
        </div>
      </div>
    </div>

    <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
      <h2 className="titles text-gray-200 text-2xl lg:text-3xl text-center pb-5 lg:pb-10">
        Testimonios
      </h2>
      <div className="w-full lg:w-3/5 mx-auto">
        <div className="testimonio w-11/12 lg:w-full mx-auto bg-blue-900 py-4 lg:py-8 px-6 lg:px-10 rounded-lg mt-10">
          <p className="text-lg">
            <span className="text-2xl">&ldquo; </span>Completamente feliz y
            agradecida con la experiencia de la lectura, me sentí sumamente
            cómoda y me encantó la forma en que desarrolla la sesión, resoné con
            cada carta, fue un viaje hacia adentro para reencontrarme, gracias
            Giselle, espero muchas personas puedan disfrutar de esta
            experiencia. <span className="text-1xl"> &rdquo;</span>
          </p>
          <p className="testimonial_link text-sm">Tania Solano (posteo FB)</p>
        </div>

        <div className="testimonio w-11/12 lg:w-full mx-auto bg-blue-900 py-4 lg:py-8 px-6 lg:px-10 rounded-lg mt-10">
          <p className="text-lg">
            <span className="text-32xl">&ldquo; </span>Hoy tuve mi sesión con
            @spiritualworker fue una experiencia que solo podría describir como
            mística, es encontrarse con uno mismo, sin filtros, sin máscaras sin
            “stage”. No hay hacia donde huir de uno mismo, para bien y para mal,
            pero este espacio para encontrarse a uno mismo que ha creado Gis, es
            único, ecléctico y seguro, está por encima del juicio y de cualquier
            otro acercamiento que yo haya tenido con las cartas. De toda la
            sesión hubo una carta que me tocó una parte muy profunda, me puso a
            llorar y me enseñó completo el panorama de mi vida, mi pasado, mi
            presente, mi propósito. Hoy más que nunca tengo claro que la razón
            por la que estamos aquí es más profunda de lo que vemos, yace en el
            alma y que no es casualidad las almas que transitan junto a
            nosotros. Estamos llamados a transformar el mundo transformándonos.{" "}
            <span className="text-1xl"> &rdquo;</span>
          </p>
          <p className="testimonial_link text-sm">@anonymous_SeekerIst</p>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default Limpieza
